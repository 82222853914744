<template>
  <div class="container">
    <h1 class="title is-2">About me</h1>
    <div class="content">
      <p>
        Hello there! I'm Nick, a Site Reliability Engineer at
          <a class="underline" href="https://tiktok.com">TikTok</a>
        where I've been building systems to rapidly deploy and scale up
        our in-house CDN infrastructure.
      </p>
      <p>
        Outside work I volunteer for transit advocacy groups in the Bay Area.
        I also occasionally write entertaining Discord bots.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutMe'
}
</script>
